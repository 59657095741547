<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>camera</title>
    <path stroke="currentColor" d="M7.543,22.293a1,1,0,1,0,1.414,1.414l2.78-2.78a.25.25,0,0,0-.176-.427H9.439a.248.248,0,0,0-.176.073Z" />
    <path stroke="currentColor" d="M15.543,23.707a1,1,0,0,0,1.414-1.414l-1.72-1.72a.248.248,0,0,0-.176-.073H12.939a.25.25,0,0,0-.176.427Z" />
    <path
      stroke="currentColor"
      d="M20.75,13.693a1,1,0,0,0-1.316-.948l-1.355.452a.25.25,0,0,1-.329-.237V12a1,1,0,0,0-1-1h-9a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h9a1,1,0,0,0,1-1v-.96a.25.25,0,0,1,.329-.237l1.354.452a1,1,0,0,0,1.317-.948Z"
    />
    <circle stroke="currentColor" cx="8.25" cy="5" r="5" />
    <circle stroke="currentColor" cx="17.75" cy="7" r="3" />
  </svg>
</template>
